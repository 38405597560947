import React, { useRef, useEffect } from "react";
import {
  INPUT_CONTAINER,
  GOOGLE_PLACE_FORMATED_ADDRESS,
  UNDEFINED,
  NAME_FIELD,
  GEOMETRY_FIELD,
  ADDRESS_FIELD,
  PLACE_CHANGED,
  ENTER_KEY,
  AE,
  SA,
} from "./constants";
import {
  INPUT_FIELD_CLASS,
  INPUT_FIELD_ERROR,
} from "../../../../public/Constants/EnumConstants";
import { InputLabelLight } from "../../Typography/InputLabel";
import ErrorTextWithIcon from "../../Typography/ErrorTextWithIcon/ErrorTextWithIcon";
import { Controller } from "react-hook-form";

const AddressAutoCompleteInputField = ({
  control,
  errors,
  label,
  field,
  height = "",
  style = "",
  name,
  defaultValue,
  setValue,
  setAutCompleteAddress = () => {},
}) => {
  const inputClassName = errors?.[field]
    ? INPUT_FIELD_ERROR
    : INPUT_FIELD_CLASS;
  const errorMessage = name
    ? errors?.[name]?.message
    : errors?.[field]?.message;

  let autoComplete;

  const autoCompleteRef = useRef(null);

  const handleAutoComplete = () => {
    try {
      const place = autoComplete?.getPlace();
      if (place?.address_components) {
        const googleAddress = GOOGLE_PLACE_FORMATED_ADDRESS(place);
        setAutCompleteAddress({
          ...googleAddress,
          address: autoCompleteRef.current.value,
        });
        setValue(field, autoCompleteRef.current.value);
      }
    } catch (e) {
      console.log(e);
    }
  };

  useEffect(() => {
    if (typeof window !== UNDEFINED) {
      try {
        autoComplete = new window.google.maps.places.Autocomplete(
          autoCompleteRef.current,
          {
            componentRestrictions: { country: [AE, SA] },
            fields: [ADDRESS_FIELD, GEOMETRY_FIELD, NAME_FIELD],
          }
        );

        autoComplete.addListener(PLACE_CHANGED, function () {
          handleAutoComplete();
        });
      } catch (e) {
        console.log(e);
      }
    }
  }, []);

  useEffect(() => {
    if (typeof window !== UNDEFINED) {
      autoCompleteRef.current.value = defaultValue;
    }
  }, [defaultValue]);

  return (
    <div className={INPUT_CONTAINER}>
      <InputLabelLight label={label} />
      <div>
        <Controller
          control={control}
          name={field}
          rules={{
            required: true,
          }}
          render={({ field: { onChange, onBlur, ref } }) => (
            <input
              className={inputClassName + height + style}
              ref={(e) => {
                ref(e);
                autoCompleteRef.current = e;
              }}
              onBlur={onBlur}
              onChange={onChange}
              onKeyPress={(e) => {
                e.key === ENTER_KEY && e.preventDefault();
              }}
              placeholder=""
            />
          )}
        />
        <ErrorTextWithIcon text={errorMessage} />
      </div>
    </div>
  );
};

export default AddressAutoCompleteInputField;
