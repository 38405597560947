import React from "react";
import { INPUT_CONTAINER } from "./constants";
import {
  INPUT_FIELD_CLASS,
  INPUT_FIELD_ERROR,
} from "../../../../public/Constants/EnumConstants";
import { InputLabelLight } from "../../Typography/InputLabel";
import { InputErrorMessage } from "../../Typography/InputErrorMessage";

const FieldArrayInput = ({
  errors,
  label,
  type,
  placeholder,
  field,
  register,
  height = "",
  style = "",
  name,
  destructuredFieldName,
}) => {
  const inputClassName = errors?.[destructuredFieldName]
    ? INPUT_FIELD_ERROR
    : INPUT_FIELD_CLASS;
  const errorMessage = name
    ? errors?.[name]?.message
    : errors?.[destructuredFieldName]?.message;
  return (
    <div className={INPUT_CONTAINER}>
      <InputLabelLight label={label} />
      <div>
        <input
          className={inputClassName + height + style}
          type={type}
          placeholder={placeholder}
          {...register(field)}
        />
        <InputErrorMessage errorMessage={errorMessage} />
      </div>
    </div>
  );
};

export default FieldArrayInput;
