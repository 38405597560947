import React from "react";

import ErrorTextWithIcon from "../../Typography/ErrorTextWithIcon/ErrorTextWithIcon";
import { PhoneNumberUtil } from "google-libphonenumber";
import { PhoneInput } from "react-international-phone";
import "react-international-phone/style.css";

const FloatingLabelPhoneField = ({
  errors,
  label,
  field,
  setValue,
  setError,
  clearErrors,
  watch,
  defaultValue,
  defaultCountry,
}) => {
  const phoneUtil = PhoneNumberUtil.getInstance();

  const isPhoneValid = (phone) => {
    try {
      phoneUtil.isValidNumber(phoneUtil.parseAndKeepRawInput(phone));
      clearErrors(field);
    } catch (error) {
      setError(field, {
        type: "error",
        message: "Phone Number is required",
      });
    }
  };

  return (
    <div className="flex-1">
      <div className={`relative`}>
        <div className="flex items-center w-full px-2.5 pb-[2px] pt-1  text-gray-900 bg-transparent rounded border border-gray-300 dark:border-gray-600 dark:focus:border-gray-500 focus:outline-none focus:ring-0 focus:border-gray-600 peer">
          <PhoneInput
            defaultCountry={defaultCountry}
            value={watch(field)}
            onChange={(phone) => setValue(field, phone)}
            onBlur={() => isPhoneValid(watch(field))}
          />
        </div>
        <label
          htmlFor={field}
          className="absolute text-sm text-gray-500 dark:text-gray-400 duration-300 transform -translate-y-4 scale-75 top-2 z-10 origin-[0] bg-white px-2 peer-focus:px-2 peer-focus:text-gray-600 peer-focus:dark:text-gray-500 peer-placeholder-shown:scale-100 peer-placeholder-shown:-translate-y-1/2 peer-placeholder-shown:top-1/2 peer-focus:top-2 peer-focus:scale-75 peer-focus:-translate-y-4 left-1"
        >
          {label}
        </label>
      </div>
      <ErrorTextWithIcon text={errors?.[field]?.message} />
    </div>
  );
};

export default FloatingLabelPhoneField;
