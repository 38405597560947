import React from "react";
import { WearThatLogo } from "../../../public/Theme";
import { CONTAINER_STYLE, LOGO_STYLE } from "./styles";

const ProgressLoader = () => {
  return (
    <div className={CONTAINER_STYLE}>
      <img src={WearThatLogo} className={LOGO_STYLE}  alt="wearthat loader"/>
    </div>
  );
};

export default ProgressLoader;
