import React from "react";
import { INPUT_CONTAINER } from "./constants";
import {
  INPUT_FIELD_CLASS,
  INPUT_FIELD_ERROR,
} from "../../../../public/Constants/EnumConstants";
import { InputLabelLight } from "../../Typography/InputLabel";
import { InputErrorMessage } from "../../Typography/InputErrorMessage";
import ErrorTextWithIcon from "../../Typography/ErrorTextWithIcon/ErrorTextWithIcon";

const InputField = ({
  errors,
  label,
  type,
  placeholder,
  field,
  register = () => {},
  handleBlur = () => {},
  height = "",
  style = "",
  name,
  disabled,
  defaultValue,
}) => {
  const inputClassName = errors?.[field]
    ? INPUT_FIELD_ERROR
    : INPUT_FIELD_CLASS;
  const errorMessage = name
    ? errors?.[name]?.message
    : errors?.[field]?.message;

  return (
    <div className={INPUT_CONTAINER}>
      <InputLabelLight label={label} />
      <div>
        <input
          className={inputClassName + height + style}
          type={type}
          placeholder={placeholder}
          {...register(field, {
            onBlur: handleBlur,
          })}
          defaultValue={defaultValue}
          disabled={disabled}
        />
        <ErrorTextWithIcon text={errorMessage} />
      </div>
    </div>
  );
};

export default InputField;
