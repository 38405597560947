export const SAVE_TEXT = "Save";
export const CANCEL_TEXT = "Cancel";

export const APARTMENT_NUMBER_LABEL = "Apartment No*";
export const APARTMENT_NUMBER_FIELD = "flat_number";
export const STREET_NUMBER_LABEL = "Street No*";
export const STREET_NUMBER_FIELD = "street";
export const AREA_LABEL = "Area*";
export const AREA_FIELD = "area";
export const EMIRATE_LABEL = "Emirate(city)*";
export const EMIRATE_FIELD = "city";
export const BUILDING_LABEL = "Building";
export const BUILDING_FIELD = "building";
export const DEFAULT_ADDRESS_LABEL = "Set as default shipping Address";
export const DEFAULT_ADDRESS_FIELD = "default_address";
export const ADDRESS = "address";
export const ADDRESS_LABLE = "Address";
