import React from "react";

import { CONTAINER_CLASS, TEXT_CLASS } from "./styles";
import { FailedIcon } from "../../../../public/Theme";

const ErrorTextWithIcon = ({ text, showIcon }) => {
  return (
    text && (
      <div className={CONTAINER_CLASS}>
        {showIcon && <img src={FailedIcon} className="mr-3" />}
        <p dir={"auto"} className={TEXT_CLASS}>
          {text}
        </p>
      </div>
    )
  );
};

export default ErrorTextWithIcon;
